@font-face {
    font-family: 'martel_sans_blackregular';
    src: url('martelsans-black-webfont.woff2') format('woff2'),
         url('martelsans-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'martel_sansbold';
    src: url('martelsans-bold-webfont.woff2') format('woff2'),
         url('martelsans-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'martel_sans_extraboldregular';
    src: url('martelsans-extrabold-webfont.woff2') format('woff2'),
         url('martelsans-extrabold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'martel_sans_extralightregular';
    src: url('martelsans-extralight-webfont.woff2') format('woff2'),
         url('martelsans-extralight-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'martel_sans_lightregular';
    src: url('martelsans-light-webfont.woff2') format('woff2'),
         url('martelsans-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'martel_sansregular';
    src: url('martelsans-regular-webfont.woff2') format('woff2'),
         url('martelsans-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'martel_sans_semiboldregular';
    src: url('martelsans-semibold-webfont.woff2') format('woff2'),
         url('martelsans-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}