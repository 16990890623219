
.full-bg-image {
  background: url('../img/jordan1_smaller.jpg') no-repeat center/contain;
  height: 25vh;
}

.heading-text {
  font-family: 'martel_sans_extraboldregular', sans-serif;
  font-size: 40px;
  letter-spacing: -3px;
  color:#ED484E;
}

.body-text {
  font-family: 'martel_sans_blackregular', sans-serif;
  color: #A42E28;
}

@media (min-width: 768px) {
  .heading-text { 
    font-size: 60px;
  }
}